// If you don't want to use TypeScript you can delete this file!
import React from "react"
import {PageProps, Link, graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Address from "../components/address"
import {MailIcon, OfficeBuildingIcon, PhoneIcon, UserIcon} from "@heroicons/react/outline";

type DataProps = {
    site: {
        buildTime: string
    }
}

const Urgent: React.FC<PageProps<DataProps>> = ({data, path}) => (
    <Layout>
        <SEO title="Impressum"/>
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">

                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
            Frist bis zum 30.11.2023
            </span>
                        <span
                            className="mt-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Förderprogramm Provenienzen NRW
            </span>
                    </h1>
                        

                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">
                        Zur Etablierung der Provenienzforschung in NRW und in Ergänzung zu den Fördermöglichkeiten des Deutschen Zentrums 
                        Kulturgutverluste (DZK) auf Bundesebene bietet das Land NRW ein Programm speziell für die Förderung von Maßnahmen 
                        im Bereich der Herkunftserforschung in allen Unrechtskontexten an. 
                        </span>
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    Um der großen Verantwortung gerecht zu werden, die Politik und Gesellschaft für den Umgang mit Kunstwerken tragen, 
                    die in der Zeit des Nationalsozialismus den Besitzern geraubt wurden, fand das Thema Provenienzforschung prominent 
                    Eingang in das Kulturgesetzbuch (KulturGB NW). Entsprechend einer wachsenden Sensibilität und Verantwortung in Bezug auf 
                    Kulturgut, das aus seinem ursprünglichen Kontext gerissen wurde, wird auch die Erforschung des kolonialen Erbes und von 
                    Entziehungen in der Zeit der ehemaligen sowjetischen Besatzungszone sowie der DDR in den Blick genommen.

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Das Förderprogramm Provenienzen NRW wurde entwickelt, um:</span> <br/>
                        <ul class="list-disc">
                            <li>
                            die Provenienzforschung in NRW hinsichtlich dieser Entzugskontexte zu intensivieren sowie
                            </li>
                            <li>
                            die Voraussetzungen dafür zu schaffen, Provenienzforschung als selbstverständliche sowie dauerhafte Kernaufgabe in den Einrichtungen zu festigen.
                            </li>                            
                        </ul>

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Förderfähig sind unter anderem:</span> <br/>
                        <ul class="list-disc">
                            <li>
                            vorbereitende Recherchearbeiten für Provenienzforschungsprojekte,
                            </li>
                            <li>
                            die Übernahme von Finanzierungsanteilen im Rahmen einer DZK-Maßnahme,
                            </li>
                            <li>
                            die Aufbereitung und Kommunikation von Ergebnissen der Herkunftserforschung 
                            (zum Beispiel Ausstellung, Publikation, Medien, Vermittlung).
                            </li>                     
                        </ul>

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Die Förderung kann in Anspruch genommen werden für:</span> <br/>
                        <ul class="list-disc">
                            <li>
                            Sach- und Materialkosten, darunter auch Werkverträge und Honorare,
                            </li>
                            <li>
                            die Deckung von Finanzierungsanteilen im Rahmen einer Antragstellung beim Deutschen Zentrum Kulturgutverluste,
                            </li>
                            <li>
                            Kosten, die im Rahmen von Kooperationen, Netzwerken und Verhandlungen sowie Rückgaben und Rückführungen (Veranstaltung, Verpackung, Versicherung, Reise- und Transport) anfallen.
                            </li>                     
                        </ul>

                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    <span className="font-bold">Antragsberechtigt sind</span> Museen, Archive und Bibliotheken in Nordrhein-Westfalen, 
                    die sich in kommunaler bzw. überwiegend öffentlicher Trägerschaft befinden (unabhängig ihrer Rechtsform). 
                    Antragstellende können die Einrichtungen selbst oder deren Träger sein.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    Eine <span className="font-bold">Beratung</span> zu den Projekten erfolgt über die Koordinationsstelle für Provenienzforschung in 
                    Nordrhein-Westfalen (KPF.NRW). <span className="font-bold">Diese ist für die Antragsteller verpflichtend.</span> Bitte sprechen Sie uns im 
                    Vorfeld einer geplanten Antragsstellung gerne an.
                    </p>

                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    Weitere Informationen zum Programm sowie die Fördergrundsätze finden Sie hier: 
                 <a href="https://mkw.nrw/system/files/media/document/file/foerdergrundsaetze_provenienzen_nrw.pdf"><br />
                 https://mkw.nrw/system/files/media/document/file/foerdergrundsaetze_provenienzen_nrw.pdf</a>.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    Die Antragsstellung erfolgt digital über die Bezirksregierung Köln.<br />
                    Kontakt: <br />
                    <a href="https://www.bezreg-koeln.nrw.de/themen/wirtschaft/kunst-und-kulturpflege">https://www.bezreg-koeln.nrw.de/themen/wirtschaft/kunst-und-kulturpflege</a><br />
                    <a href="https://www.bezreg-koeln.nrw.de/themen/wirtschaft/kunst-und-kulturpflege/antragstellung">https://www.bezreg-koeln.nrw.de/themen/wirtschaft/kunst-und-kulturpflege/antragstellung</a>
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    <span className="font-bold">Für eine Förderung im Jahr 2024 ist die Frist der 30.11.2023.</span>
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                    Ihr Kontakt im Ministerium für Kultur und Wissenschaft des Landes NRW: <br />
                    <span className="font-bold">Ruth Türnich</span><br />
                    Referat 423 Visuelle Künste, Provenienz<br />
                    Tel. +49 (211) 896 - 4899 <br />
                    E-Mail. ruth.tuernich@mkw.nrw.de <br />
                    <a href="https://www.mkw.nrw">www.mkw.nrw</a>
                    </p>

 
                </div>
            </div>
        </div>
    </Layout>
)

export default Urgent
